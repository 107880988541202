module.exports = {
    locales: ['default', 'ru', 'en'],
    defaultLocale: 'default',
    pages: {
        '*': ['header', 'footer', 'meta', 'profile', 'function_search', 'auth', 'common'],
        '/': ['indexPage', 'guide', 'demo'],
        '/guide': ['guide'],
        '/terms-of-use': ['terms-of-use'],
        '/trainer': ['trainerTasks'],
        'rgx:/guide/.*$': [
            'guide',
            'guide_exercise',
            'trainerTasks',
            'trainer',
            'sql_editor',
            'demo',
            'gpt',
        ],
        'rgx:/trainer/.+$': ['trainer', 'sql_editor'],
        'rgx:/premium.*': ['payment'],
        '/sandbox': ['sandbox', 'trainer', 'sql_editor'],
        '/certificates': ['certificates'],
        'rgx:/check-certificate.*': ['certificate_page'],
        '/promocodes': ['promocodes'],
        'rgx:/settings.*': ['setting'],
        'rgx:/handbook.*': ['handbook'],
        'rgx:/guide/.*': ['guide_article'],
        'rgx:/interview-questions': ['interview_questions'],
        'rgx:/interview-questions/.*': ['interview_questions'],
    },
    localeDetection: false,
};
